import React from 'react';
import AuthContextProvider from '@phx-husky/auth-context';
import { ScreenRecognitionProvider } from '@phx-husky/screen-recognition';

import { data } from './data';

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/inter/600.css';

export const wrapRootElement = (element) => (
  <ScreenRecognitionProvider>{AuthContextProvider(element)(data)}</ScreenRecognitionProvider>
);
