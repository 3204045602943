import {
  AmplifyCognitoAuthService,
  AuthServices,
  Config,
  DefaultLdsService,
  FieldCategory,
  LdsSystem,
} from '@phx-husky/auth-service';

const config: Config = {
  fieldsSetup: [
    {
      name: 'phone_number',
      showInSignUp: true,
      isRequired: true,
    },
    {
      name: 'given_name',
      showInSignUp: true,
      isRequired: true,
    },
    {
      name: 'family_name',
      showInSignUp: true,
      isRequired: true,
    },
    {
      name: 'birthdate',
      showInProfile: false,
    },
    {
      name: 'gender',
      showInProfile: false,
    },
    {
      name: 'website',
      showInProfile: false,
    },
    {
      name: 'phone_number',
      category: FieldCategory.PersonalInformation,
    },
  ],
  translations: {
    privacyPolicyLegal: '{1}.',
    privacyPolicyLegal_1: 'Privacy Policy',
    privacyPolicyLegalSocialSignIn: 'Privacy Policy',
    family_name: 'Last Name',
    nameProfile: 'Name',
    registerConfirmationCheckMailInfoSMS:
      'Account is not confirmed. Please enter the code that was sent to you via SMS to:',
    contactInformation: 'Shipping address(es)',
    contactInformationEditTitle: 'Edit shipping address(es)',
    accountInformation: 'Account Info',
    'address.street_addressProfile': 'Street Address',
    'address.localityProfile': 'City/Municipality',
    'address.regionProfile': 'State/Region',
    'address.postal_codeProfile': 'Zip/Postal Code',
    'address.countryProfile': 'Country',
    given_name: 'First Name',
    termsTextDefault: 'By creating an account you agree to the {1}.',
    termsTextDefault_1: 'Terms & Conditions',
  },
};

const socialMediaSignInEnabled = [
  process.env.GATSBY_COGNITO_APP_FACEBOOK_SIGN_IN,
  process.env.GATSBY_COGNITO_APP_GOOGLE_SIGN_IN,
  process.env.GATSBY_COGNITO_APP_APPLE_SIGN_IN,
].includes('true');

const services: AuthServices = {
  authService: {
    Class: AmplifyCognitoAuthService,
    params: {
      userPoolId: process.env.GATSBY_COGNITO_POOL_ID as string,
      clientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID as string,
      reCaptchaKey: process.env.GATSBY_RECAPTCHA_KEY as string,
      rememberMeConfig: { defaultValue: false, show: true },
      hasFacebookSignIn: process.env.GATSBY_COGNITO_APP_FACEBOOK_SIGN_IN === 'true',
      hasGoogleSignIn: process.env.GATSBY_COGNITO_APP_GOOGLE_SIGN_IN === 'true',
      hasAppleSignIn: process.env.GATSBY_COGNITO_APP_APPLE_SIGN_IN === 'true',
      ...(socialMediaSignInEnabled
        ? {
            oauth: {
              redirectSignIn: process.env.GATSBY_COGNITO_APP_REDIRECT_SIGN_IN as string,
              redirectSignOut: process.env.GATSBY_COGNITO_APP_REDIRECT_SIGN_OUT as string,
              domain: process.env.GATSBY_COGNITO_APP_DOMAIN as string,
              scope: process.env.GATSBY_COGNITO_APP_SCOPE?.split(',') as string[],
              responseType: 'code',
              clientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID as string,
            },
          }
        : {}),
    },
  },
  ldsService: {
    Class: DefaultLdsService,
    params: {
      system: LdsSystem.Health,
      legals: [
        {
          businessId: 'LT-CP-PL-pl-RB-Shareholders',
          content: 'legal content',
          versionId: '5e32e87e01dab10001c320fs',
          validFrom: new Date('2020-02-12'),
          validTo: new Date('2024-02-12'),
          onLegalLinkClickAction: () => {
            window.open('https://technicians.finishdishwashing.com/TermsConditions/');
          },
          description: 'TC',
          isRequired: true,
          hideCheckbox: false,
          socialTerm: false,
        },
        {
          businessId: 'LT-PP-BR-pt-VanishSignUpForm',
          content: 'privacy polcy social',
          versionId: '6193ac6a2a84c200018232b11',
          validFrom: new Date('2020-02-12'),
          validTo: new Date('2024-03-12'),
          onLegalLinkClickAction: () => {
            window.open('https://technicians.finishdishwashing.com/Privacy-Policy/');
          },
          description: 'PP',
          translationKey: 'privacyPolicyLegal',
          isRequired: true,
          hideCheckbox: false,
          socialTerm: true,
        },
        {
          businessId: 'LT-TC-BR-pt-VanishSignUpForm',
          content: 'privacy polcy',
          versionId: '6193ac6b4276bb000136033d1',
          validFrom: new Date('2021-02-12'),
          validTo: new Date('2024-02-12'),
          onLegalLinkClickAction: () => {
            window.open('https://technicians.finishdishwashing.com/Privacy-Policy/');
          },
          description: 'TC',
          translationKey: 'privacyPolicyLegal',
          isRequired: false,
          hideCheckbox: true,
          socialTerm: false,
        },
      ],
    },
  },
};

export const data = {
  config,
  services,
};
